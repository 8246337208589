export default {
  华人网: "华人网",
  活动: "活动",
  推荐: "推荐",
  报告: "报告",
  最新: "最新",
  首页: "首页",
  搜索: "搜索",
  消息: "消息",
  企业: "企业",
  合作: "合作",
  我的: "我的",
  实时汇率: "实时汇率",
  汇率换算: "汇率换算",
  没有更多了: "没有更多了",
  去登录: "去登录",
  添加订阅: "添加订阅",
  添加订阅内容: "添加订阅内容",
  编辑订阅内容: "编辑订阅内容",
  重置: "重置",
  确认订阅: "确认订阅",
  更新订阅: "更新订阅",
  最多: "最多",
  订阅: "订阅",
  热点: "热点",
  今天: "今天,",
  MM月DD日: "MM月DD日",
  来源: "来源：",
  阅读: "阅读",
  上一篇: "上一篇",
  下一篇: "下一篇",
  资讯: "资讯",
  快讯: "快讯",
  资讯详情: "资讯详情",
  请输入搜索关键词: "请输入搜索关键词",
  密码登录: "密码登录",
  未注册的手机号验证后自动创建华人网账号:
    "未注册的手机号验证后自动创建华人网账号",
  请输入手机号码: "请输入手机号码",
  请输入验证码: "请输入验证码",
  登录: "登录",
  请输入密码: "请输入密码",
  忘记密码: "忘记密码？",
  获取验证码: "获取验证码",
  验证码登录: "验证码登录",
  已发送验证码: "已发送验证码",
  实名认证: "实名认证",
  职位认证: "职位认证",
  我的合作: "我的合作",
  我的收藏: "我的收藏",
  我的活动: "我的活动",
  我的订单: "我的订单",
  华人网会员: "华人网会员",
  免广告畅享付费内容专属栏目: "免广告 · 畅享付费内容 · 专属栏目",
  首月: "首月{amount}元",
  账户余额: "账户余额",
  钻石余额: "钻石余额",
  企业入驻: "企业入驻",
  联系客服: "联系客服",
  更多功能: "更多功能",
  设置中心: "设置中心",
  意见反馈: "意见反馈",
  我的投诉: "我的投诉",
  帮助中心: "帮助中心",
  元: "元",
  个人信息: "个人信息",
  性别: "性别",
  昵称: "昵称",
  姓名: "姓名",
  手机: "手机",
  微信: "微信",
  邮箱: "邮箱",
  所在企业: "所在企业",
  职位: "职位",
  级别: "级别",
  所在城市: "所在城市",
  未填写: "未填写",
  未绑定: "未绑定",
  未选择: "未选择",
  男: "男",
  女: "女",
  请输入: "请输入",
  修改: "修改",
  编辑: "编辑",
  QQ: "QQ",
  请输入有效的邮箱地址: "请输入有效的邮箱地址",
  选择: "选择",
  全部: "全部",
  职位信息: "职位信息",
  平台审核: "平台审核",
  完成: "完成",
  填写职位信息: "填写职位信息",
  职位信息仅用于本人账号实名认证: "（职位信息仅用于本人账号实名认证）",
  请输入所在企业: "请输入所在企业",
  请输入职位: "请输入职位",
  请选择职位级别: "请选择职位级别",
  请输入所在城市: "请输入所在城市",
  上传职位信息: "上传职位信息",
  可拍照上传纸质名片或工牌: "可拍照上传纸质名片或工牌",
  可上传经过认证的第三方平台:
    "可上传经过认证的第三方平台（飞书，钉钉，企业微信）名片二维码",
  可拍照上传加盖公章的在职证明或劳动合同:
    "可拍照上传加盖公章的在职证明或劳动合同",
  提交认证: "提交认证",
  职位认证信息已提交: "职位认证信息已提交",
  您提交的职位认证资料正在审核中: "您提交的职位认证资料正在审核中",
  证件号码: "证件号码",
  返回: "返回",
  信息有误: "信息有误？",
  点此重新提交: "点此重新提交",
  啊喔没有通过职位认证呢: "啊喔，没有通过职位认证呢 ",
  已完成职位认证: "已完成职位认证",
  确认: "确认",
  消息推送设置: "消息推送设置",
  联系方式设置: "联系方式设置",
  管理收货地址: "管理收货地址",
  管理银行卡: "管理银行卡",
  用户隐私政策: "用户隐私政策",
  登录密码设置: "登录密码设置",
  支付密码设置: "支付密码设置",
  关于我们: "关于我们",
  注销账号: "注销账号",
  退出登录: "退出登录",
  设置登录密码: "设置登录密码",
  密码: "密码",
  请输入登录密码: "请输入8~32位登录密码",
  确认密码: "确认密码",
  请确认密码: "请确认密码",
  密码提示: "密码长度8-32位，须包含数字、字母、符号至少2种或以上元素",
  设置新密码: "设置新密码",
  绑定手机: "绑定手机",
  修改密码: "修改密码",
  保存: "保存",
  密码不一致: "密码不一致",
  设置密码成功: "设置密码成功",
  验证码: "验证码",
  重新发送: "重新发送",
  绑定企业: "绑定企业",
  请输入企业名称搜索: "请输入企业名称搜索",
  注意: "注意",
  绑定企业注意1: "1、系统会将您绑定在您所选的公司下，请确保准确性。",
  绑定企业注意2: "2、搜索不到公司名称，可自行输入",
  取消: "取消",
  搜不到去快捷填入: "搜不到，去快捷填入>>",
  确定并保存: "确定并保存",
  填写企业信息: "填写企业信息",
  企业名称: "企业名称",
  类型: "类型",
  多选: "（多选）",
  行业: "行业",
  请输入行业信息: "请输入行业信息",
  选择其他时需要填写: "（选择其他时需要填写）",
  请确保公司准确性: "注意：系统会将您绑定在您所填写的公司下，请确保准确性。",
  确定解绑: "确定解绑",
  确定解绑提示: "解绑之后您将成为未认证状态，重新认证后可恢复认证权益",
  确定: "确定",
  版本: "版本:",
  华人网社群: "华人网社群",
  微信号: "微信号:",
  商务合作: "商务合作",
  信息举报: "信息举报",
  检查新版本: "检查新版本",
  隐私政策: "用户服务协议及隐私政策",
  注册账号: "注册账号",
  注册: "注册",
  注册华人网账号: "注册华人网账号",
  请再次输入密码: "请再次输入密码",
};
