import { defineStore } from "pinia";

const useKeepAliveStore = defineStore("keepAlive", {
  state: () => {
    return {
      keepAliveComponents: [],
    };
  },

  getters: {
    getKeepAliveComponents(state) {
      return state.keepAliveComponents;
    },
  },

  actions: {
    // 加入到缓存队列
    setKeepAlive(component) {
      if (!this.keepAliveComponents.includes(component)) {
        this.keepAliveComponents.push(component);
      }
    },

    // 从缓存队列移除
    removeKeepAlive(component) {
      const index = this.keepAliveComponents.indexOf(component);
      if (index !== -1) {
        this.keepAliveComponents.splice(index, 1);
      }
    },
  },
});

export default useKeepAliveStore;
