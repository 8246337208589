import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "lib-flexible/flexible";
import "vant/lib/index.css";

// 注册指令
import plugins from "./plugins"; // plugins
import directive from "./directive"; // directive

// 使用懒加载
import { Lazyload } from "vant";

// svg 图标
import SvgIcon from "./components/SvgIcon.vue";
import "./assets/icons/iconfont.js";

// 国际化
import { i18n } from "./language/index.js";

// permission control
import "./permission";

const app = createApp(App);

app.use(router);
app.use(store);
app.use(plugins);
app.use(i18n);

app.component("SvgIcon", SvgIcon);

directive(app);

/**
 * 当页面需要加载大量内容时，
 * 使用懒加载可以实现延迟加载页面可视区域外的内容，
 * 从而使页面加载更流畅。
 */

app.use(Lazyload, {
  lazyComponent: true,
});

app.mount("#app");
