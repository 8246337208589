import request from "@/utils/request";
// 登录方法
export function login(username, password) {
  const data = {
    username,
    password,
  };
  return request({
    url: "/hrwCms/client/login",
    headers: {
      isToken: false,
      repeatSubmit: false,
    },
    method: "post",
    data,
  });
}

export function loginMsg(dialingCode, username, captcha) {
  const data = {
    username,
    captcha,
    dialingCode,
  };
  return request({
    url: "/hrwCms/client/captchaLogin",
    headers: {
      isToken: false,
      repeatSubmit: false,
    },
    method: "post",
    data,
  });
}

// 发送短信
export function sendMsg(dialingCode, username) {
  return request({
    url: "/hrwCms/client/sms/sendCaptcha",
    method: "post",
    data: {
      dialingCode,
      username,
    },
  });
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: "/hrwCms/client/userInfo",
    method: "get",
  });
}

// 退出方法
export function logout() {
  return request({
    url: "/hrwCms/client/logout",
    method: "put",
  });
}

// 退出方法
export function register(data) {
  return request({
    url: "/hrwCms/client/register",
    method: "post",
    data,
  });
}

// 修改用户信息
export function updateInfo(data) {
  return request({
    url: "/hrwCms/client/user/update",
    method: "post",
    data,
  });
}

// 设置密码
export function updatePwd(data) {
  return request({
    url: "/hrwCms/client/configPassword",
    method: "put",
    data,
  });
}

// 修改密码
export function modifyPwd(data) {
  return request({
    url: "/hrwCms/client/modifyPassword",
    method: "put",
    data,
  });
}

// 发送修改密码短信
export function sendModifyMsg(username) {
  return request({
    url: "/hrwCms/client/sms/sendModifyPwdCaptcha",
    method: "post",
    data: {
      username,
    },
  });
}
