import router from "./router";
import { getToken } from "@/utils/auth";
import { isRelogin } from "@/utils/request";
import useUserStore from "@/store/modules/user";
import useKeepAliceStore from "@/store/modules/keepAlive";

// --- beforeRouter --- / /
router.beforeEach((to, from, next) => {
  const KeepAliveStore = useKeepAliceStore();
  const meta = to.meta || {};

  // 加入缓存队列
  if (meta.keepAlive) {
    KeepAliveStore.setKeepAlive(to.name);
  }
  // 是否登录去处理
  if (getToken()) {
    /* has token*/
    if (to.path === "/login") {
      next({ path: "/" });
      // next();
    } else {
      // 判断当前用户是否已拉取完user_info信息
      if (useUserStore().roles.length === 0) {
        isRelogin.show = true;
        useUserStore()
          .getInfo()
          .then(() => {
            isRelogin.show = false;
            next();
          })
          .catch((err) => {
            useUserStore()
              .logOut()
              .then(() => {
                console.log(err);
                next({ path: "/" });
              })
              .catch((err) => {
                next({ path: "/" });
                console.log(err);
              });
          });
      } else {
        next();
      }
    }
  } else {
    // 没有token
    if (!to.meta.isLogin) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next(`/login?redirect=${to.fullPath}`); // 否则全部重定向到登录页
    }
  }
});

// ---afterRouter --- //
router.afterEach((to, from) => {
  const KeepAliveStore = useKeepAliceStore();

  // 如果配置了目标缓存，即：目标页面不在目标缓存中，移除当前页面缓存
  if (from.meta.keepAlivePages) {
    if (from.meta.keepAlivePages.indexOf(to.name) == -1) {
      KeepAliveStore.removeKeepAlive(from.name);
    }
  }
});
