export default {
  活动: "Activity",
  推荐: "Recommend",
  报告: "Report",
  最新: "Newest",
  首页: "Home",
  搜索: "search",
  消息: "News",
  企业: "Enterprise",
  合作: "Cooperation",
  我的: "Mine",
  实时汇率: "Real time exchange rate",
  汇率换算: "currency converter ",
  没有更多了: "There's nothing more",
  去登录: "Go login",
  添加订阅: "Add subscription",
  添加订阅内容: "Add subscription content",
  编辑订阅内容: "Edit subscription content",
  重置: "Reset",
  确认订阅: "confirm subscription ",
  更新订阅: "Update subscription",
  最多: "Most",
  订阅: "Subscribe",
  热点: "Hot Spot",
  今天: "Today,",
  MM月DD日: "DD / MM",
  全部: "全部",
  来源: "Source:",
  阅读: "read",
  上一篇: "Previous",
  下一篇: "Next",
  资讯: "real-time info",
  快讯: "news flash",
  资讯详情: "Information Details",
  请输入搜索关键词: "Please enter search keywords",
  密码登录: "Password login",
  未注册的手机号验证后自动创建华人网账号:
    "Automatically create a Chinese online account after verifying unregistered phone numbers",
  请输入手机号码: "Please enter your phone number",
  请输入验证码: "Please enter the verification code",
  登录: "Sign in",
  请输入密码: "Please input a password",
  忘记密码: "Forgot password?",
  获取验证码: "Obtain verification code",
  验证码登录: "Verification code login",
  已发送验证码: "Verification code has been sent",
  实名认证: "Real name authentication",
  职位认证: "Job certification",
  我的合作: "My collaboration",
  我的收藏: "My collection",
  我的活动: "My activities",
  我的订单: "My order",
  华人网会员: "Chinese online member",
  免广告畅享付费内容专属栏目: "Ad free · Enjoy paid content · Exclusive column",
  首月: "First month {amount} yuan",
  账户余额: "Account balance",
  钻石余额: "Diamond balance",
  企业入驻: "Enterprise settlement",
  联系客服: "Contact customer",
  更多功能: "More features",
  设置中心: "Setting",
  意见反馈: "Feedback",
  我的投诉: "Complaint",
  帮助中心: "Help Center",
  元: "Yuan",
  个人信息: "Personal information",
  性别: "Gender",
  昵称: "Nickname",
  姓名: "Name",
  手机: "Phone",
  微信: "WeChat",
  邮箱: "Mailbox",
  所在企业: "Company location",
  职位: "Position",
  级别: "Level",
  所在城市: "City",
  未填写: "Not filled in",
  未绑定: "Unbound",
  未选择: "Not selected",
  男: "Male",
  女: "Female",
  请输入: "Please enter ",
  修改: " Modify",
  编辑: "Edit ",
  QQ: "QQ",
  请输入有效的邮箱地址: "Please enter a valid email address",
};
