import { defineStore } from "pinia";
import { login, logout, getInfo, loginMsg, updateInfo } from "@/api/login";
import { getToken, setToken, removeToken } from "@/utils/auth";
import defAva from "@/assets/images/profile.png";

const useUserStore = defineStore("user", {
  state: () => ({
    token: getToken(),
    id: "",
    name: "",
    avatar: "",
    roles: [],
    permissions: [],
    clientUser: {},
  }),
  actions: {
    updateAvatar(url) {
      this.avatar = url;
      this.updateUserInfo("avatar", url);
    },

    // 登录
    login(userInfo) {
      const username = userInfo.username.trim();
      const password = userInfo.password;
      return new Promise((resolve, reject) => {
        login(username, password)
          .then((res) => {
            setToken(res.token);
            this.token = res.token;
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 验证码登录
    loginMsg(userInfo) {
      const username = userInfo.username.trim();
      const captcha = userInfo.captcha;
      const dialingCode = userInfo.dialingCode;
      return new Promise((resolve, reject) => {
        loginMsg(dialingCode, username, captcha)
          .then((res) => {
            setToken(res.token);
            this.token = res.token;
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 获取用户信息
    getInfo() {
      return new Promise((resolve, reject) => {
        getInfo()
          .then((res) => {
            const user = res.clientUser;
            this.clientUser = user;
            const avatar =
              user.avatar == "" || user.avatar == null ? defAva : user.avatar;

            // 是否登录
            this.permissions = ["system:user:login"];

            // N = 不是vip, Y 是vip
            this.roles = [user.vipFlag];

            this.id = user.userId;
            this.name = user.nickName;
            this.avatar = avatar;
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 退出系统
    logOut() {
      return new Promise((resolve, reject) => {
        logout(this.token)
          .then(() => {
            this.token = "";
            this.roles = [];
            this.permissions = [];
            removeToken();
            resolve();
          })
          .catch((error) => {
            removeToken();
            reject(error);
          });
      });
    },
    // 更改用户信息
    updateUserInfo(param, value) {
      return new Promise((resolve, reject) => {
        updateInfo({
          ...this.clientUser,
          [param]: value,
        })
          .then((res) => {
            resolve(res);
            if (res.code === 200) {
              // this.getInfo();
              this.clientUser = { ...this.clientUser, [param]: value };
            } else {
              reject(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
});

export default useUserStore;
