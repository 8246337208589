import { createI18n, useI18n } from "vue-i18n"; //引入vue-i18n组件
import zh from "./zh-CN";
import en from "./en-US";
import cache from "@/plugins/cache";

const lang = cache.local.get("LANGUAGE") || "zh-CN";
//注册i18n实例并引入语言文件
export const i18n = createI18n({
  legacy: false,
  locale: lang, // 语言标识（默认中文）
  globalInjection: true, //全局注册 $t方法
  messages: {
    zh,
    en,
  },
});
export const useLocale = (id, values) => {
  const { t } = useI18n();
  return t(id, values);
};
