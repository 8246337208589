import { createRouter, createWebHistory } from "vue-router";

/**
 *  title: string;
 * keepAlive?: boolean; // 该路由页面是否需要缓存
 *  keepAlivePages?: Array<string>;  // 配置需要缓存的跳回来源（从某页面回到该页面，该页面缓存，其他页面回到该页面，该页面不缓存）
 * canBack?: boolean; // 是否可以返回
 * isLogin?: boolean; // 是否需要登录
 */

const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/views/404Not.vue"),
  }, // 用于匹配所有不正确的路径
  {
    path: "/",
    name: "Home",
    meta: { keepAlive: true, keepAlivePages: ["DetailPage"] },
    component: () => import("@/views/home/HomePage.vue"),
  },
  {
    path: "/detail",
    name: "DetailPage",
    meta: { isLogin: false },
    component: () => import("@/views/detail/DetailPage.vue"),
  },
  {
    path: "/flash",
    name: "Flash",
    meta: { keepAlive: false, isLogin: false },
    component: () => import("@/views/flash/NewsFlash.vue"),
  },
  {
    path: "/search",
    name: "Search",
    meta: { keepAlive: true },
    component: () => import("@/views/search/SearchPage.vue"),
  },
  // 登录
  {
    path: "/login",
    name: "Login",
    meta: { keepAlive: true },
    component: () => import("@/views/login/LoginView.vue"),
  },
  // 我的
  {
    path: "/mine",
    name: "Mine",
    component: () => import("@/views/mine/MinePage.vue"),
  },
  // 收藏
  {
    path: "/collect",
    name: "Collect",
    meta: { keepAlive: false, isLogin: true },
    component: () => import("@/views/collect/CollectPage.vue"),
  },
  // 个人信息
  {
    path: "/profile",
    name: "Profile",
    meta: { keepAlive: false, isLogin: true },
    component: () => import("@/views/mine/profile/ProfilePage.vue"),
  },
  // 设置密码
  {
    path: "/bind_company",
    name: "BindCompany",
    meta: { keepAlive: false, isLogin: true },
    component: () => import("@/views/mine/profile/company/CompanyBind.vue"),
  },
  // 职位认证
  {
    path: "/jobauth",
    name: "JobAuth",
    meta: { keepAlive: false, isLogin: true },
    component: () => import("@/views/mine/jobAuth/JobAuth.vue"),
  },
  // 设置中心
  {
    path: "/setting",
    name: "Setting",
    meta: { keepAlive: false, isLogin: true },
    component: () => import("@/views/mine/setting/SettingPage.vue"),
  },
  // 设置密码
  {
    path: "/about",
    name: "AboutUs",
    component: () => import("@/views/mine/setting/AboutUs.vue"),
  },
  // 设置密码
  {
    path: "/pwd",
    name: "Password",
    meta: { keepAlive: false, isLogin: true },
    component: () => import("@/views/mine/setting/PwdSetting.vue"),
  },
  // 设置密码
  {
    path: "/pwd",
    name: "Password",
    meta: { keepAlive: false, isLogin: true },
    component: () => import("@/views/mine/setting/PwdSetting.vue"),
  },
  // 隐私政策
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import("@/views/mine/Privacy.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return { ...savedPosition, behavior: "smooth" };
    } else {
      return { top: 0, behavior: "smooth" };
    }
  },
});

export default router;
