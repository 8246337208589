// src/components/SvgIcon.vue
<template>
  <svg
    :class="svgClass"
    :style="{ width: size, height: size }"
    aria-hidden="true"
  >
    <use :xlink:href="iconClassName" :fill="color" />
  </svg>
</template>
<script setup>
import { computed, defineProps } from "vue";
const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
  className: {
    type: String,
    default: "",
  },
  color: {
    type: String,
    default: "",
  },
  size: {
    type: String,
    default: "",
  },
});
// 图标在 iconfont 中的名字
const iconClassName = computed(() => {
  return `#${props.icon}`;
});
// 给图标添加上类名
const svgClass = computed(() => {
  if (props.className) {
    return `svg-icon ${props.className}`;
  }
  return "svg-icon";
});
</script>
<style scoped>
.svg-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  position: relative;
  fill: currentColor;
  vertical-align: -2px;
}
</style>
