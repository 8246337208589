<template>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in" appear>
      <keep-alive :include="keepAliveComponents" :max="10">
        <component :is="Component" :key="route.fullPath" />
      </keep-alive>
    </transition>
  </router-view>
</template>

<script setup>
import useKeepAliceStore from "@/store/modules/keepAlive";
import { useRoute } from "vue-router";

const route = useRoute();

const KeepAliveStore = useKeepAliceStore();
const keepAliveComponents = KeepAliveStore.getKeepAliveComponents;
</script>

<style lang="less">
@import url("./assets/styles/index.less");
</style>
